import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { reset, createCampaign } from '../features/campaign/campaignsSlice'
import Spinner from '../components/Spinner'
import { getUserDetails } from '../features/auth/authSlice'
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai'
import BackButton from '../components/BackButton'
import IconSelect from '../components/IconSelect'

function AddCampaign() {
  const { user, userDetails = {} } = useSelector((state) => state.auth)
  const [formErrors, setFormErrors] = useState({})

  const { isLoading, isSuccess, isError, message } = useSelector((state) => state.campaigns)

  const [formData, setFormData] = useState({
    campaignName: '',
    rewardName: '',
    description: '',
    rules: [],
    status: 'active',
    startDate: new Date().toISOString().slice(0, 10),
    endDate: '',
    goal: '10',
    value: 'false',
    compulsory: 'false',
    referrerStampsRewarded: '1',
    referredFriendStampsRewarded: '0',
    enrolmentViaQr: true,
    enrolmentViaIntroduction: true,
    rewardNetCost: '',
    rewardRRP: '',
    tedsActive: true,
    tedsPerStamp: '',
    image: '',
    icon: 'images/icons/star.png',
    campaignType: 'Buy X, Get Y Free',
    spendPerStamp: '',
    allowAccumulation: true,
    resetAccumulationAfter: 0,
  })

  const [imageURL, setImageURL] = useState(null)

  const [includeEndDate, setIncludeEndDate] = useState(false)

  const {
    campaignName,
    campaignType,
    spendPerStamp,
    rewardName,
    description,
    status,
    startDate,
    endDate,
    goal,
    rules,
    value,
    compulsory,
    referrerStampsRewarded,
    referredFriendStampsRewarded,
    enrolmentViaQr,
    enrolmentViaIntroduction,
    rewardNetCost,
    rewardRRP,
    tedsActive,
    tedsPerStamp,
    image,
    icon,
    allowAccumulation,
    resetAccumulationAfter,
  } = formData

  console.log(formData, 'formData')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  //fetch user details
  useEffect(() => {
    if (!userDetails) {
      dispatch(getUserDetails())
    }
  }, [dispatch, userDetails])

  // sets tedsActive to false if country is not UK
  useEffect(() => {
    if (userDetails && userDetails.address && userDetails.address.country !== 'GB') {
      setFormData((prevState) => ({
        ...prevState,
        tedsActive: false,
      }))
    }
  }, [userDetails])

  useEffect(() => {
    if (isSuccess) {
      toast.success(message)
      dispatch(reset())
    }

    if (isError) {
      toast.error(message.message)
    }
  }, [dispatch, isSuccess, isError, message])

  const validateForm = () => {
    const errors = {}
    if (!campaignName.trim()) errors.campaignName = 'Campaign Name is required'
    if (!rewardName.trim()) errors.rewardName = 'Reward Name is required'

    // Validate startDate
    if (!startDate) errors.startDate = 'Start Date is required'

    // Validate endDate
    if (includeEndDate && !endDate) errors.endDate = 'End Date is required'
    else if (includeEndDate && new Date(endDate) <= new Date(startDate)) errors.endDate = 'End Date must be after Start Date'

    // Validate goal
    if (!goal) errors.goal = 'Goal is required'
    else if (isNaN(goal) || goal <= 0) errors.goal = 'Goal must be a positive number'

    // Validate rewardNetCost and rewardRRP
    if (!rewardNetCost.trim()) errors.rewardNetCost = 'Reward Net Cost is required'
    else if (isNaN(rewardNetCost) || rewardNetCost < 0) errors.rewardNetCost = 'Reward Net Cost must be a number'

    if (!rewardRRP.trim()) errors.rewardRRP = 'Reward RRP is required'
    else if (isNaN(rewardRRP) || rewardRRP < 0) errors.rewardRRP = 'Reward RRP must be a  number'

    // Validate referrerStampsRewarded and referredFriendStampsRewarded
    if (referrerStampsRewarded === '') errors.referrerStampsRewarded = 'Referrer Stamps Rewarded is required'
    else if (isNaN(referrerStampsRewarded) || Number(referrerStampsRewarded) < 0)
      errors.referrerStampsRewarded = 'Referrer Stamps Rewarded must be a non-negative number'

    if (referredFriendStampsRewarded === '') errors.referredFriendStampsRewarded = 'Referred Friend Stamps Rewarded is required'
    else if (isNaN(referredFriendStampsRewarded) || Number(referredFriendStampsRewarded) < 0)
      errors.referredFriendStampsRewarded = 'Referred Friend Stamps Rewarded must be a non-negative number'

    // Validate spendPerStamp when campaignType is 'Spend X Amount To Earn a Stamp'
    if (campaignType === 'Spend X Amount To Earn a Stamp' && (!spendPerStamp || isNaN(spendPerStamp) || spendPerStamp <= 0)) {
      errors.spendPerStamp = 'Amount to Spend Per Stamp is required and must be a positive number'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  useEffect(() => {
    if (isError) {
      toast.error(message.message)
    }

    if (isSuccess) {
      navigate('/campaigns')
    }

    dispatch(reset())
  }, [isError, isSuccess, user, message, navigate, dispatch])

  const onChange = (e) => {
    const { name, value } = e.target

    setFormData((prevState) => {
      let newValue = value

      // Convert 'true' and 'false' strings to boolean values
      if (value === 'true' || value === 'false') {
        newValue = value === 'true'
      }
      // Convert numeric strings to numbers for specific fields
      if (['referrerStampsRewarded', 'referredFriendStampsRewarded', 'goal', 'spendPerStamp'].includes(name)) {
        newValue = value === '' ? '' : Number(value)
      }

      // Convert numeric strings to numbers
      if (!isNaN(value) && value !== '') {
        newValue = Number(value)
      }

      let newState = { ...prevState, [name]: newValue }

      // Reset resetAccumulationAfter when allowAccumulation is set to false
      if (name === 'allowAccumulation' && newValue === false) {
        newState.resetAccumulationAfter = 0
      }

      // Handle campaign type change
      if (name === 'campaignType') {
        if (value === 'Spend X Amount To Earn a Stamp') {
          newState.value = true
          newState.compulsory = true
        } else {
          // Reset to default values for other campaign types
          newState.value = false
          newState.compulsory = false
        }
      }

      return newState
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (!validateForm()) {
      return
    }
    const campaignData = new FormData()
    campaignData.append('campaignName', campaignName)
    campaignData.append('campaignType', campaignType)
    campaignData.append('rewardName', rewardName)
    campaignData.append('description', description)
    campaignData.append('status', status)
    campaignData.append('startDate', startDate)
    campaignData.append('endDate', endDate)
    campaignData.append('goal', goal)
    campaignData.append('value', value)
    campaignData.append('compulsory', compulsory)
    campaignData.append('referrerStampsRewarded', referrerStampsRewarded)
    campaignData.append('referredFriendStampsRewarded', referredFriendStampsRewarded)
    campaignData.append('enrolmentViaQr', enrolmentViaQr)
    campaignData.append('enrolmentViaIntroduction', enrolmentViaIntroduction)
    campaignData.append('rewardNetCost', rewardNetCost)
    campaignData.append('rewardRRP', rewardRRP)
    campaignData.append('tedsActive', tedsActive)
    campaignData.append('tedsPerStamp', tedsPerStamp)

    // Add new fields
    if (campaignType === 'Spend X Amount To Earn a Stamp') {
      campaignData.append('spendPerStamp', spendPerStamp)
      campaignData.append('allowAccumulation', allowAccumulation)
      if (formData.allowAccumulation === true) {
        campaignData.append('resetAccumulationAfter', resetAccumulationAfter)
      }
    }

    // Append each rule to the FormData
    rules.forEach((rule, index) => {
      campaignData.append(`rules[${index}]`, rule)
    })

    if (image) {
      campaignData.append('image', image)
    }

    if (icon) {
      // if icon is a File object, append it as a file
      if (icon instanceof File) {
        campaignData.append('icon', icon, icon.name)
      } else {
        // otherwise, append it as a string
        campaignData.append('icon', icon)
      }
    }

    for (let pair of campaignData.entries()) {
    }

    dispatch(createCampaign(campaignData))
  }

  // adds a new empty rule to the rules array
  const addRule = () => {
    setFormData({
      ...formData,
      rules: [...rules, ''],
    })
  }

  const removeRule = (index) => {
    const newRules = [...rules]
    newRules.splice(index, 1)
    setFormData({
      ...formData,
      rules: newRules,
    })
  }

  // updates the rules array when a rule input is changed
  const handleRuleChange = (e, index) => {
    const newRules = [...formData.rules]
    newRules[index] = e.target.value
    setFormData((prevState) => ({ ...prevState, rules: newRules }))
  }

  const onFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setImageURL(url) // Update imageURL state

      setFormData((prevState) => ({
        ...prevState,
        image: file,
      }))
    } else {
      setImageURL(null) // Update imageURL state
      setFormData((prevState) => ({
        ...prevState,
        image: null,
      }))
    }
  }

  const handleCurrencyInputChange = (e) => {
    const { name, value } = e.target
    const formattedValue = value.replace(/[^0-9.]/g, '') // Remove non-numeric characters
    setFormData({ ...formData, [name]: formattedValue })
  }

  useEffect(() => {
    if (rewardRRP && goal) {
      const totalTEDS = parseFloat(rewardRRP) * 10 // Convert Reward RRP to TEDS
      const tedsPerStamp = totalTEDS / parseInt(goal, 10) // Calculate TEDS per stamp

      // Round the value depending on the decimal part
      const roundedTedsPerStamp = tedsPerStamp % 1 >= 0.5 ? Math.ceil(tedsPerStamp) : Math.floor(tedsPerStamp)

      setFormData((prevState) => ({
        ...prevState,
        tedsPerStamp: roundedTedsPerStamp.toString(),
      }))
    }
  }, [rewardRRP, goal])
  if (isLoading || !userDetails) {
    return <Spinner />
  }

  return (
    <>
      <div className='flex'>
        <BackButton />
      </div>
      <div className='md:mx-20 pb-40'>
        <h1 className='text-3xl md:text-4xl mb-8 font-bold font-mono'>Add Campaign</h1>
        <form onSubmit={onSubmit}>
          <div className='flex flex-col md:flex-row'>
            {/* Campaign Name*/}
            <div className='flex flex-col w-full mr-3 mb-3'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Campaign Name</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Enter a unique and catchy name for your loyalty campaign here. This is the first thing customers will see, so choose a name that reflects the spirit of your offer and aligns with your brand identity. Think of it as the title of your promise to your customers – make it enticing and clear.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>

              <input
                type='text'
                className='campaign-input'
                placeholder='Enter Campaign Name'
                id='campaignName'
                name='campaignName'
                value={campaignName}
                onChange={onChange}
              />
              {formErrors.campaignName && <p className='text-red-500 text-sm'>{formErrors.campaignName}</p>}
            </div>

            {/* Reward Name*/}

            <div className='flex flex-col w-full mb-3'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Reward Name</label>
                <div
                  className='campaign-tooltip'
                  data-tip='Choose a reward name that will excite and motivate your customers to participate in your loyalty campaign. This is the "prize" they are working towards, so it should sound irresistible and valuable.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <input
                type='text'
                className='campaign-input'
                placeholder='Enter Reward Name (e.g., "Free Cup of Coffee")'
                id='rewardName'
                name='rewardName'
                value={rewardName}
                onChange={onChange}
              />
              {formErrors.rewardName && <p className='text-red-500 text-sm'>{formErrors.rewardName}</p>}
            </div>
          </div>

          {/* Campaign Type */}
          <div className='flex flex-col w-full mb-3'>
            <div className='flex justify-between'>
              <label className='campaign-label'>Campaign Type</label>
              <div
                className='campaign-tooltip'
                data-tip='Choose the type of campaign. "Buy X, Get Y Free (earn a stamp on each purchase)" rewards a stamp for each purchase. "Spend X Amount To Earn a Stamp" requires a specific spending amount to earn a stamp.'>
                <AiOutlineInfoCircle />
              </div>
            </div>
            <select
              className='campaign-input bg-white appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary'
              id='campaignType'
              name='campaignType'
              value={formData.campaignType}
              onChange={onChange}>
              <option value='Buy X, Get Y Free'>Buy X, Get Y Free (Earn a Stamp on Each Purchase)</option>
              <option value='Spend X Amount To Earn a Stamp'>Spend X Amount To Earn a Stamp</option>
            </select>
          </div>
          {/* Campaign Description */}

          <div className='flex flex-col mb-3'>
            <div className='flex justify-between'>
              <label className='campaign-label'>Campaign Description</label>
              <div
                className='campaign-tooltip'
                data-tip='Describe the essence of your loyalty campaign, focusing on what your customers can gain. Use this space to paint a picture of the value they’ll receive, like the joy of a complimentary coffee or the satisfaction of a free haircut after a set number of visits. Keep it enticing and succinct, allowing the rewards to take center stage.'>
                <AiOutlineInfoCircle />
              </div>
            </div>
            <textarea
              className='campaign-input'
              placeholder='Describe your campaign (e.g., "Join our journey to 10 delicious coffees and enjoy the 10th one on us!")'
              id='description'
              name='description'
              value={description}
              onChange={onChange}
            />
          </div>

          {/* Rules Section */}
          <div className='flex flex-col w-full mb-1'>
            <div className='flex justify-between items-center '>
              <label className='campaign-label'>Rules</label>
              <div
                className='campaign-tooltip'
                data-tip='Establish clear rules for your campaign here. Define how customers can earn rewards and any conditions or limitations. For example, “One stamp per customer per visit”  Keep each rule straightforward to ensure customers understand how to participate.'>
                <AiOutlineInfoCircle />
              </div>
            </div>
            {rules &&
              rules.map((rule, index) => (
                <label key={index} className='input-group input-group-md w-full  relative mb-2'>
                  <span className='w-20'>Rule {index + 1}</span>
                  <input type='text' value={rule} onChange={(e) => handleRuleChange(e, index)} className='text-base w-full' />
                  <button type='button' className='absolute right-0 top-0 p-1 text-gray-600 hover:text-red-600' onClick={() => removeRule(index)}>
                    <AiOutlineCloseCircle />
                  </button>
                </label>
              ))}
          </div>
          {/* Button to add rules */}
          <button type='button' onClick={addRule} className='btn flex w-full  text-xs md:text-base mb-6'>
            Add campaign rule for customers
          </button>

          <IconSelect onIconChange={(icon) => setFormData({ ...formData, icon })} />

          {/* Start/ end date */}
          <div className='flex mb-3 mt-2'>
            <input
              type='checkbox'
              id='includeEndDate'
              name='includeEndDate'
              checked={includeEndDate}
              onChange={() => setIncludeEndDate(!includeEndDate)}
            />
            <label className='ml-2' htmlFor='includeEndDate'>
              Would you like to include an end date? (customers will not be able to earn stamps or claim rewards after this date)
            </label>
          </div>
          <div className='flex flex-col md:flex-row mb-3'>
            <div className='flex flex-col flex-auto md:mr-3 mb-3'>
              <div className='flex justify-between items-center mb-1'>
                <label className='campaign-label' htmlFor='startDate'>
                  Start Date:
                </label>
                <div
                  className='campaign-tooltip'
                  data-tip='Choose the start date for your campaign. This is when your customers can start earning rewards, so set a date that aligns with your promotional strategy.'>
                  <AiOutlineInfoCircle className='text-md' />
                </div>
              </div>
              <input
                className='campaign-input bg-white appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary'
                value={startDate}
                onChange={onChange}
                type='date'
                id='startDate'
                name='startDate'
              />
              {formErrors.startDate && <p className='text-red-500 text-sm'>{formErrors.startDate}</p>}
            </div>

            {includeEndDate && ( // if includeEndDate is true, show the end date input
              <div className='flex flex-col flex-auto'>
                <div className='flex justify-between items-center mb-1'>
                  <label className='campaign-label' htmlFor='endDate'>
                    End Date:
                  </label>
                  <div
                    className='campaign-tooltip'
                    data-tip='Set the closing date for your campaign. This defines the timeframe in which customers can participate and complete their loyalty objectives.'>
                    <AiOutlineInfoCircle className='text-md' />
                  </div>
                </div>
                <input className='campaign-input' value={endDate} onChange={onChange} type='date' id='endDate' name='endDate' />
                {formErrors.endDate && <p className='text-red-500 text-sm'>{formErrors.endDate}</p>}
              </div>
            )}
          </div>

          {/* status/goal */}
          <div className='flex flex-col md:flex-row mt-3'>
            {/* Campaign Status */}
            <div className='flex flex-col flex-auto w-full md:mr-3 mb-3'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Campaign Status</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='This status indicates whether your campaign is currently active or paused. An active status means that customers can join and participate in the campaign. If paused, the campaign is temporarily inactive and will not be visible to customers. Use this feature to manage the visibility of your offers and control the flow of your loyalty program.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='campaign-input bg-white appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary'
                id='status'
                name='status'
                value={status}
                onChange={onChange}>
                <option value='active'>Active</option>
                <option value='paused'>Paused</option>
              </select>
            </div>

            {/* Stamps to collect for goal */}
            <div className='flex flex-col flex-auto w-full'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Stamps to Collect for Goal</label>
                <div
                  className='campaign-tooltip'
                  data-tip='Specify the number of stamps a customer must collect to achieve the reward in this loyalty campaign. Set a goal that motivates customers to return and make repeated purchases. Consider your profit margins and customer behavior when determining this number. Make sure it`s attainable, yet profitable for your business.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='campaign-input bg-white appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary'
                id='goal'
                name='goal'
                value={goal}
                onChange={onChange}>
                {[...Array(20)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
              {formErrors.goal && <p className='text-red-500 text-sm'>{formErrors.goal}</p>}
            </div>
          </div>
          <div className='divider'></div>

          {/* Amount to Spend Per Stamp */}
          {formData.campaignType === 'Spend X Amount To Earn a Stamp' && (
            <>
              <div className='flex flex-col md:flex-row md:space-x-3'>
                {/* Amount to Spend Per Stamp */}
                <div className='flex flex-col w-full md:w-1/2 mb-3'>
                  <div className='flex justify-between'>
                    <label className='campaign-label'>Amount to Spend Per Stamp Rewarded</label>
                    <div
                      className='campaign-tooltip'
                      data-tip='Specify the amount a customer must spend to earn one stamp. This value sets the spending threshold for each stamp in your "Spend X Amount" campaign. For example, if set to $10, customers will earn one stamp for every $10 spent. Choose an amount that balances customer engagement with your business goals.'>
                      <AiOutlineInfoCircle />
                    </div>
                  </div>
                  <input
                    type='text'
                    className='campaign-input'
                    placeholder={`${userDetails.currencySymbol} Enter Amount to Spend Per Stamp`}
                    id='spendPerStamp'
                    name='spendPerStamp'
                    value={` ${userDetails.currencySymbol} ${spendPerStamp}`}
                    onChange={handleCurrencyInputChange}
                    required
                  />
                  {formErrors.spendPerStamp && <p className='text-red-500 text-sm'>{formErrors.spendPerStamp}</p>}
                </div>

                {/* Allow accumulation across visits */}
                <div className='flex flex-col w-full md:w-1/2 mb-3'>
                  <div className='flex justify-between'>
                    <label className='campaign-label'>Allow accumulation across visits</label>
                    <div
                      className='campaign-tooltip'
                      data-tip='Choose whether to allow customers to accumulate spend across multiple visits. If set to "Yes", partial progress towards a stamp will be saved between visits. For example, if a stamp requires $10 and a customer spends $6, that progress will be saved for their next visit. If set to "No", customers must reach the required spend amount in a single visit to earn a stamp.'>
                      <AiOutlineInfoCircle />
                    </div>
                  </div>
                  <select
                    className='campaign-input bg-white appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary'
                    id='allowAccumulation'
                    name='allowAccumulation'
                    value={formData.allowAccumulation}
                    onChange={onChange}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </div>
              </div>

              {/* Reset Accumulation After */}
              {formData.allowAccumulation === true && (
                <div className='flex flex-col w-full mb-3'>
                  <div className='flex justify-between'>
                    <label className='campaign-label'>Reset Accumulation After</label>
                    <div className='campaign-tooltip' data-tip='Set how long partial progress towards a stamp is saved.'>
                      <AiOutlineInfoCircle />
                    </div>
                  </div>
                  <input
                    type='range'
                    min='0'
                    max='60'
                    value={formData.resetAccumulationAfter}
                    className='range range-xs'
                    id='resetAccumulationAfter'
                    name='resetAccumulationAfter'
                    onChange={onChange}
                  />
                  <div className='text-center font-bold'>
                    {formData.resetAccumulationAfter === 0 ? 'Never' : `${formData.resetAccumulationAfter} days`}
                  </div>
                </div>
              )}
            </>
          )}

          {/* Net Cost + RRP */}
          <div className='flex flex-col md:flex-row'>
            {/* Reward Net Cost */}
            <div className='flex flex-col flex-auto w-full md:mr-3'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Reward Net Cost (excluding VAT)</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Enter the net cost of the reward. This is the cost to your business for providing the reward, excluding any markup. Accurately recording the net cost helps in financial planning and assessment of the campaign’s profitability.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <input
                type='text'
                className='campaign-input'
                placeholder={`${userDetails.currencySymbol} Enter Reward Net Cost (excluding VAT)`}
                id='rewardNetCost'
                name='rewardNetCost'
                value={` ${userDetails.currencySymbol} ${rewardNetCost}`}
                onChange={handleCurrencyInputChange}
              />
              {formErrors.rewardNetCost && <p className='text-red-500 text-sm'>{formErrors.rewardNetCost}</p>}
            </div>

            <div className='flex flex-col flex-auto w-full  md:pt-0 pt-3'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Reward RRP (including VAT)</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Enter the Recommended Retail Price (RRP) of the reward. This is the price customers would typically pay if they were to purchase the reward outright. This information can be useful for marketing purposes to highlight the value of the loyalty reward to customers and to emphasize the savings they are making by participating in your campaign.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <input
                type='text'
                className='campaign-input'
                placeholder={`${userDetails.currencySymbol} Enter Reward RRP (including VAT)`}
                id='rewardRRP'
                name='rewardRRP'
                value={`${userDetails.currencySymbol} ${rewardRRP}`}
                onChange={handleCurrencyInputChange}
              />
              {formErrors.rewardRRP && <p className='text-red-500 text-sm'>{formErrors.rewardRRP}</p>}
            </div>
          </div>
          <div className='flex flex-col md:flex-row mt-0 md:mt-3'>
            {/* Allow ted */}
            {/* Participation in TED Trading System */}
            {/* {userDetails.address.country === 'GB' && (
              <div className='flex flex-col flex-auto w-full md:mr-3 mt-3 md:mt-0'>
                <div className='flex justify-between'>
                  <label className='campaign-label'>Participate in TED Trading System</label>
                  <div
                    className='campaign-tooltip'
                    data-tip='Select "Allow" to enable participation in the TED trading system for this campaign, allowing transactions using TEDs. Select "Disallow" if you do not wish to include this campaign in the TED trading system.'>
                    <AiOutlineInfoCircle />
                  </div>
                </div>
                <select className='campaign-input' value={tedsActive} id='tedsActive' name='tedsActive' onChange={onChange}>
                  <option value='true'>Allow</option>
                  <option value='false'>Disallow</option>
                </select>
                {formErrors.tedsActive && <p className='text-red-500 text-sm'>{formErrors.tedsActive}</p>}
              </div>
            )} */}
            {tedsActive === true && (
              <div className='flex flex-col flex-auto w-full'>
                <div className='flex justify-between md:pt-0 pt-3'>
                  <label className='campaign-label'>TEDS Per Stamp</label>{' '}
                  <div
                    className='campaign-tooltip'
                    data-tip={`This field displays the TEDS value automatically assigned to each stamp. It's calculated based on the Reward RRP and the number of stamps required to earn the reward. The calculation is: (Reward RRP * 10) / Number of Stamps. For example, if the Reward RRP is ${userDetails.currencySymbol}20.00 (equivalent to 200 TEDS) and 10 stamps are required, the TEDS Per Stamp value would be 20 (200 / 10). This value updates automatically when you change either the Reward RRP or the number of stamps, and it cannot be manually edited.`}>
                    <AiOutlineInfoCircle />
                  </div>
                </div>
                <input
                  type='text'
                  className='campaign-input'
                  placeholder='Enter TEDS Per Stamp'
                  id='tedsPerStamp'
                  name='tedsPerStamp'
                  value={tedsPerStamp}
                  onChange={onChange}
                />
              </div>
            )}
          </div>
          <div className='divider'></div>

          {/* ALlow teds + costPerTed */}

          {/* server record order /compulsory */}
          <div className='flex flex-col md:flex-row'>
            {/* Allow recording value of order */}
            <div className='flex flex-col flex-auto w-full md:mr-3 mb-3 '>
              <div className='flex justify-between'>
                <label className='campaign-label'>Record Order Value</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Toggle this option to enable servers to input the value of the order when scanning a customers QR code. This feature provides valuable insights into customer spending patterns and can be essential for accurate accounting and financial analysis. By tracking transaction values, you gain data to optimize your loyalty campaigns and tailor them to customer behavior.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className={`campaign-input bg-white appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary ${
                  formData.campaignType === 'Spend X Amount To Earn a Stamp' ? 'bg-gray-300' : ''
                }`}
                id='value'
                name='value'
                value={value}
                onChange={(e) => {
                  onChange(e)
                  if (e.target.value === 'false') {
                    setFormData((prevState) => ({
                      ...prevState,
                      compulsory: 'false',
                    }))
                  }
                }}
                required
                disabled={formData.campaignType === 'Spend X Amount To Earn a Stamp'}>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </select>
            </div>

            {/* Compulsory Order Value Recording */}
            {value === 'true' && (
              <div className='flex flex-col flex-auto w-full'>
                <div className='flex justify-between'>
                  <label className='campaign-label'>Compulsory Order Value Recording</label>
                  <div
                    className='campaign-tooltip'
                    data-tip='Set this to "Yes" to mandate servers to enter the order value each time a customer`s QR code is scanned. This ensures consistent data collection across all transactions, which is crucial for thorough financial tracking, analyzing customer spend trends, and enhancing the accuracy of your loyalty program`s success metrics.'>
                    <AiOutlineInfoCircle />
                  </div>
                </div>
                <select
                  className={`campaign-input bg-white appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary ${
                    formData.campaignType === 'Spend X Amount To Earn a Stamp' ? 'bg-gray-300' : ''
                  }`}
                  id='compulsory'
                  name='compulsory'
                  value={compulsory}
                  onChange={onChange}
                  required
                  disabled={formData.campaignType === 'Spend X Amount To Earn a Stamp'}>
                  <option value='true'>Yes</option>
                  <option value='false'>No</option>
                </select>
              </div>
            )}
          </div>
          <div className='divider'></div>

          {/* reward stamps */}
          <div className='flex flex-col md:flex-row'>
            {/* Stamps to referrer */}
            <div className='flex flex-col flex-auto w-full md:mr-3 mb-3'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Referrer Reward Stamps</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Determine how many stamps the referrer will receive when their referred friend earns their first stamp. This incentive encourages your current customers to bring in new patrons and helps to expand your customer base. Set this reward carefully to ensure it is enticing enough to motivate referrals, while also maintaining the profitability of your campaign.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='campaign-input bg-white appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary'
                id='referrerStampsRewarded'
                name='referrerStampsRewarded'
                value={referrerStampsRewarded}
                onChange={onChange}>
                <option value='0'>Don't reward any stamps</option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
              </select>
            </div>
            {/* Stamps to friend */}
            <div className='flex flex-col flex-auto w-full'>
              <div className='flex justify-between'>
                <label className='campaign-label'>New Customer Reward Stamps</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Choose the number of bonus stamps a newly referred customer will receive on earning their first stamp. This welcome reward acts as a powerful incentive for new customers to start engaging with your loyalty program. It’s an effective way to thank them for joining through a referral and can significantly boost the early experience with your brand.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='campaign-input bg-white appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary'
                id='referredFriendStampsRewarded'
                name='referredFriendStampsRewarded'
                value={referredFriendStampsRewarded}
                onChange={onChange}>
                <option value='0'>Don't reward any stamps</option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
              </select>
            </div>
          </div>
          <div className='divider'></div>
          {/* Enrolment */}
          <div className='flex flex-col md:flex-row'>
            {/* Enrolment Via QR */}
            <div className='flex flex-col flex-auto w-full md:mr-3 mb-3'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Enrollment via QR Code</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Specify whether customers can enroll in this campaign by scanning the QR code. "Allowed" means the campaign will be visible and joinable through QR code scanning, facilitating easy access for customers. "Not Allowed" restricts enrollment to other methods, which can be useful for exclusive or targeted offers.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='campaign-input bg-white appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary'
                id='enrolmentViaQr'
                name='enrolmentViaQr'
                value={enrolmentViaQr}
                onChange={onChange}
                required>
                <option value='true'>Allowed</option>
                <option value='false'>Not allowed</option>
              </select>
            </div>
            {/* Enrolment Via Introduction */}
            <div className='flex flex-col flex-auto w-full mb-3'>
              <div className='flex justify-between'>
                <label className='campaign-label'>Enrollment Via Introduction</label>{' '}
                <div
                  className='campaign-tooltip'
                  data-tip='Enable this to allow "Enrollment Via Introduction", where customers can be added to this campaign through a referral or directly by a partner in-store. When "Allowed", it fosters growth through the refer-a-friend system and personal in-shop introductions, leveraging word-of-mouth and direct interactions for campaign expansion.'>
                  <AiOutlineInfoCircle />
                </div>
              </div>
              <select
                className='campaign-input bg-white appearance-none border border-gray-300 rounded-md w-full p-4 md:p-6 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-primary'
                name='enrolmentViaIntroduction'
                value={enrolmentViaIntroduction}
                onChange={onChange}
                required>
                <option value='true'>Allowed</option>
                <option value='false'>Not allowed</option>
              </select>
            </div>
          </div>

          {/* file upload */}

          <div className='flex'>
            <div className='mr-8 w-full mb-3'>
              <h1 className='flex mb-2 text-xs md:text-base'> Choose an Image for Your Reward (optimal aspect ratio is 1:1, such as 300px/300px)</h1>
              <input type='file' className='campaign-input' id='image' name='image' onChange={onFileChange} accept='image/*' />
            </div>
            <div className='w-full'>
              {imageURL && (
                <div className='flex flex-col items-center'>
                  <h2 className='font-bold mt-4 m-3'>Reward Image Preview:</h2>
                  <img src={imageURL} className='flex justify-center items-center rounded-md' alt='selected icon' width='100' height='100' />
                </div>
              )}
            </div>
          </div>

          {/* Add Campaign Button */}
          <div className='flex flex-col  md:flex-row justify-between items-center  md:space-y-0 md:space-x-6'>
            <button className='flex my-6 p-6 btn-primary  text-white w-full md:w-auto font-bold justify-center items-center space-x-4 font-sans rounded-md shadow-lg px-9  hover:bg-opacity-90  hover:shadow-lg border transition hover:-translate-y-0.5 duration-150'>
              <span>Add Campaign</span>
              <img src='images/next-arrow.svg' alt='' />
            </button>
          </div>
          {Object.keys(formErrors).length > 0 && (
            <p className='text-red-500 text-lg text-left font-bold mb-10'>
              The new campaign is missing some details, please review and fix the highlighted fields to successfully add the campaign.
            </p>
          )}
        </form>
      </div>
    </>
  )
}

export default AddCampaign

import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { getProducts } from '../features/payments/paymentsSlice'
import Spinner from '../components/Spinner'

const PickSubscription = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [referralCode, setReferralCode] = useState('')
  const [utmParams, setUtmParams] = useState({})
  const { loading, error } = useSelector((state) => state.subscription)
  const { products } = useSelector((state) => state.payments)
  console.log(products, 'products')

  useEffect(() => {
    dispatch(getProducts())

    // Capture referral code and UTM parameters from URL
    const params = new URLSearchParams(location.search)
    const ref = params.get('ref')
    if (ref) {
      setReferralCode(ref)
    }

    const utmFields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
    const capturedUtmParams = {}
    utmFields.forEach((field) => {
      const value = params.get(field)
      if (value) {
        capturedUtmParams[field] = value
      }
    })
    setUtmParams(capturedUtmParams)
  }, [dispatch, location])

  const handleSubscribe = (variation) => {
    // Include referral code and UTM parameters in navigation
    const queryParams = new URLSearchParams()
    if (referralCode) {
      queryParams.append('ref', referralCode)
    }
    Object.entries(utmParams).forEach(([key, value]) => {
      queryParams.append(key, value)
    })
    const queryString = queryParams.toString()
    navigate(`/purchase/${variation.id}${queryString ? `?${queryString}` : ''}`)
  }

  const formatPrice = (amount) => {
    return (parseInt(amount) / 100).toFixed(2)
  }

  console.log('Products:', products) // Add this line to log the products

  if (loading) return <Spinner />
  if (error) return <p>{error}</p>
  if (!products || products.length === 0) return <p>No products available.</p>

  return (
    <div className='p-6 '>
      <h1 className='font-bold text-3xl font-black pb-3'>Join Our Innovative Loyalty Platform: Connect & Reward</h1>
      <p className='text-xl mb-6'>Empowering businesses of all types to create and manage loyalty campaigns.</p>
      <h2 className='font-bold text-2xl font-black pb-3'>How it works:</h2>
      <ol className='list-decimal list-inside mb-6'>
        <li>Sign up to join our app as a partner.</li>
        <li>Create your unique loyalty programs.</li>
        <li>Receive a unique QR code for customers to join your loyalty scheme.</li>
        <li>Customers can scan your QR code to view and join your campaigns.</li>
        <li>Customers get a QR code to collect stamps and redeem rewards.</li>
      </ol>
      <div className='flex flex-wrap justify-center items-start gap-4'>
        {products
          .filter((product) => product.presentAtAllLocations === true && !product.subscriptionPlanData?.name?.includes('Without Trial'))
          .map((product) => {
            console.log('Individual product:', product)
            if (!product || !product.subscriptionPlanData || !product.subscriptionPlanData.subscriptionPlanVariations) {
              console.log('Invalid product or missing variations:', product)
              return null
            }

            return product.subscriptionPlanData.subscriptionPlanVariations.map((variation) => {
              const variationData = variation.subscriptionPlanVariationData
              const pricingPhase = variationData.phases.find((phase) => phase.ordinal === '1')
              const phaseCadence = pricingPhase?.cadence
              const priceAmount = pricingPhase?.pricing?.priceMoney?.amount

              return (
                <div key={variation.id} className='card gradient text-white max-w-xs p-8 m-4 shadow-2xl'>
                  <div className='card-body text-center mb-8 border-b border-white/10 pb-8'>
                    <p className='uppercase tracking-widest text-xs mb-6 text-black'>{variationData.name}</p>
                    <div className='mb-4'>
                      <div className='flex justify-center items-start gap-1'>
                        <span className='text-4xl align-top text-black'>£</span>
                        <span className='text-7xl text-black'>{formatPrice(priceAmount)}</span>
                        <div className='flex flex-col items-start text-4xl text-black'>
                          <span>{phaseCadence === 'MONTHLY' ? 'mo' : 'yr'}</span>
                        </div>
                      </div>
                      <button
                        onClick={() => handleSubscribe(variation)}
                        className='btn btn-block btn-secondary text-white-500 hover:scale-105 focus:outline-none mt-2'>
                        Subscribe to {phaseCadence === 'MONTHLY' ? 'Monthly' : 'Yearly'} Plan
                      </button>
                    </div>
                  </div>
                </div>
              )
            })
          })}
      </div>
    </div>
  )
}

export default PickSubscription

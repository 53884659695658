import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import serversReducer from '../features/server/serversSlice'
import customersReducer from '../features/customer/customersSlice'
import campaignsReducer from '../features/campaign/campaignsSlice'
import messagesReducer from '../features/message/messagesSlice'
import resetMiddleware from '../components/resetMiddleware'
import subscriptionReducer from '../features/subscription/subscriptionSlice'
import tedsReducer from '../features/teds/tedsSlice'
import profileReducer from '../features/profile/profileSlice'
import paymentsReducer from '../features/payments/paymentsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    servers: serversReducer,
    customers: customersReducer,
    campaigns: campaignsReducer,
    messages: messagesReducer,
    subscription: subscriptionReducer,
    teds: tedsReducer,
    profile: profileReducer,
    payments: paymentsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(resetMiddleware),
})

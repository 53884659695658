import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/subscription`

const initiateCheckoutSession = async (checkoutData) => {
  try {
    const products = await api.post(`${API_URL}/create-checkout-session`, { checkoutData })
    return products.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 500) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const existingMemberCheckoutSession = async (checkoutData) => {
  try {
    const products = await api.post(`${API_URL}/existing-member-checkout-session`, { checkoutData })
    return products.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 500) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// Verify token
const verifyToken = async (verificationToken) => {
  try {
    const response = await api.get(`${API_URL}/verify-email/${verificationToken}`)

    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 500) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// resends email to verify account
const resendVerificationEmail = async (clientID) => {
  try {
    const response = await api.post(`${API_URL}/resend-verification/${clientID}`, {})
    return response.data
  } catch (error) {
    let errorMessage = 'An unexpected error occurred.'

    // check for error response
    if (error.response) {
      // status code related error
      switch (error.response.status) {
        case 400:
          errorMessage = 'Bad Request. The server could not understand the request due to invalid syntax.'
          break
        case 401:
          errorMessage = 'Unauthorized. The client must authenticate itself to get the requested response.'
          break
        case 403:
          errorMessage = 'Forbidden. The client does not have access rights to the content.'
          break
        case 404:
          errorMessage = 'Not Found. The server could not find the requested resource.'
          break
        case 500:
          errorMessage = 'Internal Server Error. The server has encountered problem'
          break
        default:
          errorMessage = error.response.data.error || errorMessage
          break
      }
    } else if (error.request) {
      // request made but no response
      errorMessage = 'The request was made but no response was received.'
    } else {
      // other errors
      errorMessage = error.message || errorMessage
    }

    throw new Error(errorMessage)
  }
}

const manageSubscription = async (stripeCustomerID) => {
  try {
    const response = await api.post(`${API_URL}/portal-session`, { stripeCustomerID })
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 500) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const initiateStripeConnect = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/stripe-connect/onboarding`, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 500) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getPartnerStripeAccount = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/stripe-connect/check`, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 500) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const cancelSubscription = async (squareCustomerID, token) => {
  try {
    console.log('cancel subscription service called')
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.post(`${API_URL}/cancel-subscription`, { squareCustomerID }, config)
    return response.data
  } catch (error) {
    // Follow same error handling pattern as other functions
    if (error.response) {
      if (error.response.status === 500) {
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const updateCustomerCard = async (cardData, token) => {
  try {
    const response = await api.post(`${API_URL}/update-card`, cardData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.log('error 🤪', error)
    // Throw the error response directly since it's already in the correct format
    throw (
      error.response?.data || {
        success: false,
        message: 'An unexpected error occurred',
        error: {
          code: 'UNKNOWN_ERROR',
          category: 'SYSTEM_ERROR',
          detail: error.message,
          field: null,
        },
      }
    )
  }
}

const subscriptionService = {
  initiateCheckoutSession,
  verifyToken,
  manageSubscription,
  existingMemberCheckoutSession,
  resendVerificationEmail,
  initiateStripeConnect,
  getPartnerStripeAccount,
  cancelSubscription,
  updateCustomerCard,
}

export default subscriptionService

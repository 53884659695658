import React from 'react'
import { useDispatch } from 'react-redux'
import { reset } from '../features/auth/authSlice'

const ModalPopup = ({ id, onClose, title, children }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(reset()) // Reset all postcode checker states
    if (onClose) onClose()
  }

  return (
    <>
      <input type='checkbox' id={id} className='modal-toggle' />
      <div className='modal modal-bottom sm:modal-middle custom-zindex'>
        <div className='modal-box relative'>
          <label htmlFor={id} className='btn btn-sm btn-circle absolute right-2 top-2' onClick={handleClose}>
            ✕
          </label>
          {title && <h3 className='font-bold text-lg mb-4'>{title}</h3>}
          {children}
          <div className='modal-action'>
            <label htmlFor={id} className='btn' onClick={handleClose}>
              Close
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalPopup

import { Link } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { fetchServers, deleteServer, pauseServer, reset } from '../features/server/serversSlice'
import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Spinner from '../components/Spinner'
import toast from 'react-hot-toast'
import { FaPauseCircle } from 'react-icons/fa'
import { RiDeleteBin5Line } from 'react-icons/ri'

const Servers = () => {
  const { isSuccess, isError, isLoading, servers, server, message } = useSelector((state) => state.servers)

  const dispatch = useDispatch()

  const [serverToPause, setServerToPause] = useState(null)

  // Reset state when there is a success or error
  useEffect(() => {
    if (isSuccess) {
      toast.success(message)
      dispatch(reset())
    }

    if (isError) {
      toast.error(message.message)
      dispatch(reset())
    }
  }, [dispatch, isSuccess, isError, message])

  // Fetch servers and servers status
  useEffect(() => {
    dispatch(fetchServers())
  }, [dispatch, server])

  const handleDeleteServer = (id) => {
    dispatch(deleteServer(id)).then(() => {
      dispatch(fetchServers())
    })
  }

  const deleteServerModal = () => {
    document.getElementById('my-modal-6').checked = true
  }

  const pauseServerModal = (server) => {
    setServerToPause(server)
    document.getElementById('pause-modal').checked = true
  }

  const handlePauseUnpauseServer = () => {
    if (serverToPause) {
      dispatch(pauseServer(serverToPause._id)).then(() => {
        document.getElementById('pause-modal').checked = false
        setServerToPause(null)
      })
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <Helmet>
        <title>Servers | Loyalty Club PLC</title>
      </Helmet>
      <div className='flex justify-center mb-5 pb-20'>
        <div className='w-full md:w-52 mt-8'>
          <Link className='btn btn-primary w-full md:w-52' to='/addserver'>
            Add a server
          </Link>
        </div>
      </div>
      {servers.length === 0 ? (
        <div className='flex flex-col items-center'>
          <p className='text-lg font-bold mb-4'>
            No servers added yet.{' '}
            <label htmlFor='server_explanation_modal' className='text-secondary cursor-pointer'>
              What is a Server?
            </label>
          </p>
        </div>
      ) : (
        servers.map((server) => (
          // servers outer container
          <div key={server._id} className='relative py-4 grid grid-cols-1 gap-4 lg:mx-40'>
            {/* server container */}
            <div className='bg-white p-8 rounded-lg shadow-lg flex justify-between mx-0 md:mx-8'>
              {/* Left details */}
              <div className='flex flex-col justify-between items-left text-sm'>
                <div className='flex text-left mb-3 text-lg font-bold'>{server.name}</div>
                <div className='flex'>Position - {server.position}</div>
                <div className='text-gray-400 flex'>Server Created on {new Date(server.createdAt).toLocaleDateString()}</div>
              </div>
              {/* Right details */}
              <div className='flex flex-col justify-evenly'>
                <p className={`absolute bottom-0 top-1 right-0 badge text-white ${server.status === 'paused' ? 'btn-error' : 'btn-success'}`}>
                  {server.status}
                </p>
                <button className='flex flex-nowrap btn mb-2 btn-sm w-28 text-xs md:text-sm btn-secondary' onClick={() => pauseServerModal(server)}>
                  <FaPauseCircle />
                  {server.status === 'paused' ? 'Unpause' : 'Pause'}
                </button>

                <div className={`${server.status !== 'paused' ? 'tooltip' : ''}`} data-tip='You must pause this server before you can delete it'>
                  <div className='cursor-pointer'>
                    <button
                      className='flex flex-nowrap btn mb-2 btn-sm w-28 btn-error'
                      onClick={() => deleteServerModal()}
                      disabled={server.status !== 'paused'}>
                      <RiDeleteBin5Line />
                      Remove
                    </button>
                  </div>
                </div>

                <Link to={`/server/${server._id}`}>
                  <button className='flex w-28 flex-nowrap btn text-xs btn-sm btn-outline'>View</button>
                </Link>
              </div>
            </div>
            {/* Delete Server Modal */}
            <input type='checkbox' id='delete-modal' className='modal-toggle' />
            <div className='modal modal-bottom sm:modal-middle'>
              <div className='modal-box'>
                <h3 className='font-bold text-lg'>Warning! You're about to permanently delete this server</h3>
                <p className='py-4'>Are you sure that you would like to remove this server? This operation cannot be undone</p>
                <div className='modal-action flex justify-between'>
                  <label onClick={() => handleDeleteServer(server._id)} htmlFor='delete-modal' className='btn btn-error'>
                    Confirm Delete Server
                  </label>
                  <label htmlFor='delete-modal' className='btn'>
                    Cancel
                  </label>
                </div>
              </div>
            </div>

            {/* Pause Server Modal */}
            <input type='checkbox' id='pause-modal' className='modal-toggle ' />
            <div className='modal modal-bottom sm:modal-middle custom-zindex'>
              <div className='modal-box'>
                <h3 className='font-bold text-lg'>{serverToPause && serverToPause.status === 'paused' ? 'Unpause Server' : 'Pause Server'}</h3>
                <p className='py-4'>
                  Are you sure you want to {serverToPause && serverToPause.status === 'paused' ? 'unpause' : 'pause'} this server?
                </p>
                <div className='modal-action flex justify-between'>
                  <label onClick={handlePauseUnpauseServer} htmlFor='pause-modal' className='btn btn-primary'>
                    Confirm
                  </label>
                  <label htmlFor='pause-modal' className='btn'>
                    Cancel
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))
      )}

      {/* Server Explanation Modal */}
      <input type='checkbox' id='server_explanation_modal' className='modal-toggle' />
      <div className='modal custom-zindex' role='dialog'>
        <div className='modal-box relative'>
          <label htmlFor='server_explanation_modal' className='btn btn-sm btn-circle absolute right-2 top-2'>
            ✕
          </label>
          <h2 className='font-bold text-2xl mb-4'>Understanding Servers in Your Loyalty Program</h2>

          <h3 className='font-bold text-lg mt-6 mb-2'>What is a Server?</h3>
          <p className='mb-4 text-left'>
            A server is a key player in your loyalty program's success story. They're the face of your business - your barbers, hairdressers, waiters,
            or any staff member who directly interacts with customers. In the context of our loyalty program, servers have a special role:
          </p>
          <ul className='list-none pl-0 mb-4'>
            <li className='flex items-start mb-2'>
              <span className='mr-2 flex-shrink-0'>🏷️</span>
              <span className='text-left'>Scanning customer QR codes</span>
            </li>
            <li className='flex items-start mb-2'>
              <span className='mr-2 flex-shrink-0'>⭐</span>
              <span className='text-left'>Applying stamps to customer accounts</span>
            </li>
            <li className='flex items-start mb-2'>
              <span className='mr-2 flex-shrink-0'>🎁</span>
              <span className='text-left'>Assisting with reward redemptions</span>
            </li>
          </ul>
          <p className='mb-4 text-left'>
            Think of your servers as the bridge between your loyalty program and your customers, making the rewards experience smooth and enjoyable.
          </p>

          <h3 className='font-bold text-lg mt-6 mb-2'>Why Add a Server to Your Account?</h3>
          <p className='mb-4 text-left'>Adding servers to your loyalty program account unlocks a treasure trove of benefits:</p>

          <h4 className='font-bold mt-4 mb-2 flex items-center'>
            <span className='mr-2 flex-shrink-0'>📊</span>
            <span>1. Performance Tracking</span>
          </h4>
          <p className='mb-2 text-left'>Keep tabs on each server's contribution to your loyalty program. This insight helps you:</p>
          <ul className='list-disc pl-6 mb-4'>
            <li className='text-left mb-1'>Recognize top performers</li>
            <li className='text-left mb-1'>Identify areas where additional training might be needed</li>
            <li className='text-left mb-1'>Ensure consistent program execution across your team</li>
          </ul>

          <h4 className='font-bold mt-4 mb-2 flex items-center'>
            <span className='mr-2 flex-shrink-0'>📈</span>
            <span>2. Detailed Analytics</span>
          </h4>
          <p className='mb-2 text-left'>Dive deep into server-specific data:</p>
          <ul className='list-disc pl-6 mb-4'>
            <li className='text-left mb-1'>Number of customers served</li>
            <li className='text-left mb-1'>Stamps applied</li>
            <li className='text-left mb-1'>Rewards redeemed</li>
          </ul>
          <p className='mb-4 text-left'>These metrics paint a clear picture of each server's impact on your loyalty program's success.</p>

          <h4 className='font-bold mt-4 mb-2 flex items-center'>
            <span className='mr-2 flex-shrink-0'>🔍</span>
            <span>3. Enhanced Accountability</span>
          </h4>
          <p className='mb-2 text-left'>Maintain high standards in your loyalty program by:</p>
          <ul className='list-disc pl-6 mb-4'>
            <li className='text-left mb-1'>Ensuring servers follow program guidelines</li>
            <li className='text-left mb-1'>Providing a consistent experience for all customers</li>
            <li className='text-left mb-1'>Upholding the integrity of your loyalty initiative</li>
          </ul>

          <h4 className='font-bold mt-4 mb-2 flex items-center'>
            <span className='mr-2 flex-shrink-0'>🧠</span>
            <span>4. Informed Decision Making</span>
          </h4>
          <p className='mb-2 text-left'>Leverage data to drive your business forward:</p>
          <ul className='list-disc pl-6 mb-4'>
            <li className='text-left mb-1'>Optimize staff training programs</li>
            <li className='text-left mb-1'>Allocate resources more effectively</li>
            <li className='text-left mb-1'>Fine-tune your loyalty program for maximum impact</li>
          </ul>

          <h4 className='font-bold mt-4 mb-2 flex items-center'>
            <span className='mr-2 flex-shrink-0'>😊</span>
            <span>5. Seamless Customer Experience</span>
          </h4>
          <p className='mb-2 text-left'>Well-managed servers contribute to:</p>
          <ul className='list-disc pl-6 mb-4'>
            <li className='text-left mb-1'>Smooth loyalty program operations</li>
            <li className='text-left mb-1'>Increased customer satisfaction</li>
            <li className='text-left mb-1'>Stronger customer loyalty</li>
          </ul>

          <p className='mt-6 font-bold'>
            By adding servers to your account, you're not just tracking performance - you're empowering your team to deliver an exceptional loyalty
            experience that keeps customers coming back for more!
          </p>

          <div className='modal-action'>
            <label htmlFor='server_explanation_modal' className='modal-backdrop btn btn-primary'>
              Close
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export default Servers

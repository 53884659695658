export const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null

  return (
    <div className='fixed inset-0 z-50 overflow-y-auto'>
      <div className='flex items-center justify-center min-h-screen px-4'>
        <div className='fixed inset-0 bg-black opacity-30' onClick={onClose}></div>
        <div className='relative bg-white rounded-lg max-w-lg w-full'>
          <button onClick={onClose} className='absolute top-4 right-4 text-gray-400 hover:text-gray-500'>
            <span className='sr-only'>Close</span>×
          </button>
          {children}
        </div>
      </div>
    </div>
  )
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localStorage
const userFromStorage = localStorage.getItem('user')
const regDetailsFromStorage = localStorage.getItem('regDetails')
const user = userFromStorage ? JSON.parse(userFromStorage) : null
const regDetails = regDetailsFromStorage ? JSON.parse(regDetailsFromStorage) : null

// Ensure the user object from localStorage has a wallet property
if (user && !user.wallet) {
  user.wallet = {
    userAccount: {},
    tedsBalance: 0,
    madTedsBalance: 0,
    transactionHistory: [],
  }
}

const initialState = {
  user: user,
  currentCustomerID: '',
  userDetails: null,
  socialLinks: '',
  locationInfo: '',
  isError: false,
  isSuccess: false,
  isLoading: false,
  postCodeStatusIsSuccess: false,
  postCodeStatusIsLoading: false,
  postCodeStatusMessage: '',
  postcodeSectors: [],
  updateUserDetailsIsLoading: false,
  updateUserDetailsIsSuccess: false,
  message: '',
  images: {},
  postCodeStatus: null,
  passwordResetEmail: false,
  paymentStatus: 'processing',
  registrationDetails: regDetails,
  introducedClients: [],
}

//* Check if user is authenticated */
export const authCheck = createAsyncThunk('auth/authCheck', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.authCheck(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *register new client
export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
  try {
    return await authService.register(user)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// logout user
export const logout = createAsyncThunk('auth/logout', async () => {
  authService.logout()
})

// *Update client details
export const updateUserDetails = createAsyncThunk('auth/updateUserDetails', async (userData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token

    return await authService.updateUserDetails(userData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Update server details
export const updateServerDetails = createAsyncThunk('auth/updateServerDetails', async (userData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.updateServerDetails(userData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *get client details
export const getUserDetails = createAsyncThunk('auth/getUserDetails', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.getUserDetails(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Update social links
export const updateSocialLinks = createAsyncThunk('auth/updateSocialLinks', async (userData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.updateSocialLinks(userData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Update location info
export const updateLocationInfo = createAsyncThunk('auth/updateLocationInfo', async (userData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.updateLocationInfo(userData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *get location Details
export const getLocationDetails = createAsyncThunk('auth/getLocationDetails', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.getLocationDetails(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *get social links
export const getSocialLinks = createAsyncThunk('auth/getSocialLinks', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.getSocialLinks(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// * check post code
export const checkPostCodeStatus = createAsyncThunk('auth/checkPostCode', async (postcodeData, thunkAPI) => {
  try {
    return await authService.checkPostCodeStatus(postcodeData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Get image
export const fetchImage = createAsyncThunk('auth/fetchImage', async (id, { getState, rejectWithValue }) => {
  try {
    const res = await authService.fetchImage(id)
    return { id, image: res }
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

//Reset password
export const resetPassword = createAsyncThunk('auth/resetpassword', async (emailAddress, thunkAPI) => {
  try {
    return await authService.resetPassword(emailAddress)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// *Update client/server password
export const updateUserPassword = createAsyncThunk('auth/update-user-password', async (userData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token

    return await authService.updateUserPassword(userData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Update Qr Code
export const updateQrCode = createAsyncThunk('auth/updateQrCode', async (formData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.updateQrCode(formData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *fetch Customer ID
export const fetchCustomerID = createAsyncThunk('auth/getCustomerID', async (shortCode, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.getCustomerID(shortCode, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// check payment status after registration
export const checkPaymentStatus = createAsyncThunk('auth/checkPaymentStatus', async (clientID, thunkAPI) => {
  try {
    const response = await authService.checkPaymentStatus(clientID)
    return response.data.status
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

// *get client details
export const getPostcodeSectors = createAsyncThunk('auth/getPostcodeSectors', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.getPostcodeSectors(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *update postcode sectors
export const updatePostcodeSectors = createAsyncThunk('auth/updatePostcodeSectors', async (userData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.updatePostcodeSectors(token, userData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// **Get introduced clients
export const getIntroducedClients = createAsyncThunk('auth/getIntroducedClients', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.getIntroducedClients(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload
    },
    updateWallet: (state, action) => {
      // This assumes the payload is the new wallet balance
      state.user.wallet = action.payload
      // Sync with local storage
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.postCodeStatusIsLoading = false
      state.postCodeStatusMessage = ''
      state.postCodeStatusIsSuccess = false
      state.isSuccess = false
      state.message = ''
      state.postCodeStatus = ''
      state.updateUserDetailsIsLoading = false
      state.updateUserDetailsIsSuccess = false
      state.currentCustomerID = ''
    },
    resetEmailSent: (state) => {
      state.passwordResetEmail = ''
    },
    updateSubscriptionStatus: (state, action) => {
      if (state.userDetails) {
        console.log('action.payload', action.payload)
        state.userDetails = {
          ...state.userDetails,
          subscriptionIsActive: true,
          subscriptionStatus: 'active',
          // Add any other subscription-related fields that need updating
          // ...action.payload,
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(register.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.registrationDetails = action.payload
    })
    builder.addCase(register.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(login.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.user = action.payload
    })
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null
      state.userDetails = null
    })
    builder.addCase(updateUserDetails.pending, (state) => {
      state.updateUserDetailsIsLoading = true
    })
    builder.addCase(updateUserDetails.fulfilled, (state, action) => {
      state.updateUserDetailsIsLoading = false
      state.updateUserDetailsIsSuccess = true
      state.userDetails = action.payload
    })
    builder.addCase(updateUserDetails.rejected, (state, action) => {
      state.updateUserDetailsIsLoading = false
      state.isError = true
    })
    builder.addCase(updateServerDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateServerDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.userDetails = action.payload
    })
    builder.addCase(updateServerDetails.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getUserDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.userDetails = action.payload
    })
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(updateSocialLinks.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateSocialLinks.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.socialLinks = action.payload
      state.message = 'Social Links Updated Successfully'
    })
    builder.addCase(updateSocialLinks.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(updateLocationInfo.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateLocationInfo.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.locationInfo = action.payload
      state.message = 'Location updated successfully'
    })
    builder.addCase(updateLocationInfo.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getSocialLinks.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSocialLinks.fulfilled, (state, action) => {
      state.isLoading = false

      state.socialLinks = action.payload
    })
    builder.addCase(getSocialLinks.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getLocationDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLocationDetails.fulfilled, (state, action) => {
      state.isLoading = false

      state.locationInfo = action.payload
    })
    builder.addCase(getLocationDetails.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    // Update builder for fetchImage
    builder
      .addCase(fetchImage.pending, (state) => {
        state.imageIsLoading = true
      })
      .addCase(fetchImage.fulfilled, (state, action) => {
        state.imageIsLoading = false
        state.imageIsSuccess = true
        state.images[action.payload.id] = action.payload.image
      })
      .addCase(fetchImage.rejected, (state, action) => {
        state.imageIsSuccess = false
        state.imageIsError = true
        state.imageErrorMessage = action.payload
      })
    builder.addCase(checkPostCodeStatus.pending, (state) => {
      state.postCodeStatusIsLoading = true
    })
    builder.addCase(checkPostCodeStatus.fulfilled, (state, action) => {
      state.postCodeStatusIsLoading = false
      state.postCodeStatusIsSuccess = true
      state.postCodeStatusMessage = action.payload.message
      state.postCodeStatus = action.payload.success
    })
    builder
      .addCase(checkPostCodeStatus.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.postCodeStatusMessage = action.payload
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.passwordResetEmail = action.payload
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    builder.addCase(updateUserPassword.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateUserPassword.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.message = action.payload
    })
    builder.addCase(updateUserPassword.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(updateQrCode.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateQrCode.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.message = action.payload
    })
    builder.addCase(updateQrCode.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchCustomerID.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchCustomerID.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.currentCustomerID = action.payload
    })
    builder.addCase(fetchCustomerID.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(authCheck.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(authCheck.fulfilled, (state) => {
      state.isLoading = false
      state.isAuthenticated = true
    })
    builder.addCase(authCheck.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.isAuthenticated = false
    })
    builder
      .addCase(checkPaymentStatus.pending, (state) => {
        state.paymentStatus = 'processing'
      })
      .addCase(checkPaymentStatus.fulfilled, (state, action) => {
        state.paymentStatus = action.payload
      })
      .addCase(checkPaymentStatus.rejected, (state) => {
        state.paymentStatus = 'failed'
      })
    builder.addCase(getPostcodeSectors.pending, (state) => {})
    builder.addCase(getPostcodeSectors.fulfilled, (state, action) => {
      state.postcodeSectors = action.payload
    })
    builder.addCase(getPostcodeSectors.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(updatePostcodeSectors.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updatePostcodeSectors.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.postcodeSectors = action.payload
      state.message = action.payload.message
    })
    builder
      .addCase(updatePostcodeSectors.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getIntroducedClients.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getIntroducedClients.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.introducedClients = action.payload.clients
      })
      .addCase(getIntroducedClients.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset, updateServerCount, resetEmailSent, updateWallet, setUserDetails, updateSubscriptionStatus } = authSlice.actions

export default authSlice.reducer

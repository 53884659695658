import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-hot-toast'
import { updateCustomerCard } from '../features/payments/paymentsSlice'
import { Modal } from './Modal'

const UpdatePaymentModal = ({ isOpen, onClose, customerId }) => {
  const dispatch = useDispatch()
  const [payments, setPayments] = useState(null)
  const [card, setCard] = useState(null)
  const [isInitializing, setIsInitializing] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let mounted = true

    const loadSquareSDK = async () => {
      try {
        // Wait for DOM to be ready
        await new Promise((resolve) => setTimeout(resolve, 100))

        if (!window.Square) {
          const script = document.createElement('script')
          script.src =
            process.env.NODE_ENV === 'development' ? 'https://sandbox.web.squarecdn.com/v1/square.js' : 'https://web.squarecdn.com/v1/square.js'
          script.async = true

          const scriptLoadPromise = new Promise((resolve, reject) => {
            script.onload = resolve
            script.onerror = () => reject(new Error('Failed to load Square SDK'))
          })

          document.body.appendChild(script)
          await scriptLoadPromise
        }

        // Wait for Square to be fully loaded
        await new Promise((resolve) => setTimeout(resolve, 1000))

        if (mounted) {
          await initializeSquare()
        }
      } catch (error) {
        console.error('Failed to load Square SDK:', error)
        if (mounted) {
          toast.error('Failed to initialize payment form. Please try again.')
          setIsInitializing(false)
        }
      }
    }

    const initializeSquare = async () => {
      try {
        const cardElement = document.getElementById('update-card-container')
        if (!cardElement) {
          throw new Error('Card container element not found')
        }

        const paymentsInstance = await window.Square.payments(process.env.REACT_APP_SQUARE_APPLICATION_ID, process.env.REACT_APP_SQUARE_LOCATION_ID)

        if (mounted) {
          setPayments(paymentsInstance)
          const cardInstance = await paymentsInstance.card()
          await cardInstance.attach('#update-card-container')
          setCard(cardInstance)
        }
      } catch (error) {
        console.error('Error initializing Square Payments:', error)
        throw error
      } finally {
        if (mounted) {
          setIsInitializing(false)
        }
      }
    }

    if (isOpen) {
      loadSquareSDK()
    }

    return () => {
      mounted = false
      if (card) {
        card.destroy()
      }
    }
  }, [isOpen])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      if (!card) {
        throw new Error('Card payment method not initialized')
      }

      // Get card nonce
      const result = await card.tokenize()
      if (result.status === 'OK') {
        // Add verification step
        const verificationDetails = {
          amount: '1.00',
          currencyCode: 'USD',
          intent: 'STORE',
          billingContact: {
            // Add any billing contact details if needed
          },
        }

        const verificationToken = await payments.verifyBuyer(result.token, verificationDetails)

        // Dispatch action to update the card with both nonce and verification token
        await dispatch(
          updateCustomerCard({
            customerId,
            cardNonce: result.token,
            // cardNonce: 'bnon:bank-nonce-insufficient-funds',
            verificationToken: verificationToken.token,
          })
        ).unwrap()

        toast.success('Payment method updated successfully')
        onClose()
      } else {
        throw new Error(result.errors[0].message)
      }
    } catch (error) {
      console.error('Error updating payment method:', error)
      toast.error(error.message || 'Failed to update payment method')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='p-6'>
        <h2 className='text-2xl font-bold mb-6'>Update Payment Method</h2>

        <form onSubmit={handleSubmit}>
          <div id='update-card-container' className='mb-4'>
            {isInitializing && (
              <div className='flex justify-center items-center min-h-[200px] flex-col'>
                <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary'></div>
                <span className='mt-2 text-gray-600'>Loading...</span>
              </div>
            )}
          </div>

          <div className='flex justify-end gap-4'>
            <button type='button' onClick={onClose} className='btn btn-ghost' disabled={isInitializing || loading}>
              Close
            </button>
            <button type='submit' className='btn btn-primary' disabled={isInitializing || loading}>
              {loading ? 'Updating...' : 'Update Payment Method'}
            </button>
          </div>
        </form>

        <p className='text-sm text-gray-600 mt-4'>
          If you're experiencing issues, please ensure any ad blockers or content blockers are disabled for this site.
        </p>
      </div>
    </Modal>
  )
}

export default UpdatePaymentModal
